html {
  scroll-behavior: smooth;
  font-family: "Roboto";
}

.ReactCollapse--collapse {
  /* transition: height 500ms; */
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: url(#gradient-svg-default);
}

/* .CircularProgressbar-path {
  stroke: red;
} */

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

input:focus::placeholder {
  color: transparent;
}

.styles-module_tooltip__mnnfp {
  opacity: 1; 
}
